class MindContentJsonData {
    constructor() {
        this.version = 1.0;
        this.data = [];
        this.files = [];
        this.id = "";
        this.mid = "";
        this.lastsavetime = -1;
    }
}
export default MindContentJsonData