
class NewLocalMindParameter {
    constructor() {
        this.parentEntityId = "";
        this.type = 1
        this.mindDisplayType = 0
        this.nodeLayoutType = 1
        this.lineLayout = 1
        this.isEmpty = false
        this.styleIndex = -1
    }

    getJson() {
        let json = JSON.stringify(this, function (key, val) {
            if (typeof val === "function") {
                return val + '';
            }
            return val
        });
        return json;
    }

    format(json) {
        let mindParameter = new NewLocalMindParameter()
        let data = JSON.parse(json)
        for (var key in data) {
            let keyValue = data[key]
            mindParameter[key] = keyValue
        }
        return mindParameter
    }

    isValid() {
        return this.styleIndex != -1;
    }
}
export default NewLocalMindParameter