import HttpServerConfig from "../../../../common/HttpServerConfig";
import { md5 } from "../../../../common/netWork/base";
import NewLocalMindParameter from "../../../../core/core/dataformat/fileImport/NewLocalMindParameter";
import Util from "../../../../utils/Util";
import HashMap from "../../base/HashMap";
import MindMapLocalData from "./data/MindMapLocalData";
import MindSheets from "./data/MindSheets";
import SheetData from "./data/SheetData";
import MapSaveData from "./MapSaveData";
import MindMapZipConstant from "./MindMapZipConstant";

class LoadLocalXJMind {

    constructor(path) {
        this.appPath = ""
        this.mindMapLocalData = new MindMapLocalData()
        this.saveData = new MapSaveData()
        this.resources = new HashMap()
        this.parameter = new NewLocalMindParameter()
    }

    reset() {
        this.mindMapLocalData = new MindMapLocalData()
        this.saveData = new MapSaveData()
        this.resources = new HashMap()
        this.parameter = new NewLocalMindParameter()
    }

    loadMainContent(path, localpath, rootId, sheetId) {
        this.setPath(path, localpath)
        let that = this;
        return new Promise(async resolve => {
            if (localpath == null || localpath.length == 0) {
                resolve(null)
                return
            }
            let contentJSPath = localpath + MindMapZipConstant.FileSeparator + MindMapZipConstant.MindZIPContentPath
            that.loadJson(contentJSPath).then((data) => {
                let json = data;
                let saveData = JSON.parse(json)
                that.mindMapLocalData.id = saveData.id
                that.mindMapLocalData.mid = saveData.mid
                if (saveData.data != null && saveData.data.length > 0) {
                    let cell = saveData.data[0]
                    that.setRootId(cell.id)
                    let sheetIndex = 0;
                    if (sheetId != undefined && sheetId != null && sheetId.length > 0) {
                        for (let index = 0; index < saveData.data.length; index++) {
                            const element = saveData.data[index];
                            if (element.id == sheetId) {
                                cell = element;
                                sheetIndex = index;
                                break 
                            }
                        }
                    }
                    
                    let JsPath = localpath + MindMapZipConstant.FileSeparator + MindMapZipConstant.MindZIPContentFolderName + MindMapZipConstant.FileSeparator + cell.fileName
                    that.loadJson(JsPath).then((data) => {
                        
                        let contentJs = data;
                        if (that.parameter.isValid()) {
                            resolve({content:contentJs, 
                                mindType:cell.mindType, 
                                index:sheetIndex,
                                styleIndex: that.parameter.styleIndex,
                                mindDisplayType: that.parameter.mindDisplayType,
                                isEmpty: that.parameter.isEmpty,
                                nodeLayoutType:that.parameter.nodeLayoutType,
                                name: cell.name,
                                lineLayout:that.parameter.lineLayout})
                        } else {
                            resolve({content:contentJs, 
                                mindType:cell.mindType, 
                                index:sheetIndex,
                                styleIndex: 1,
                                mindDisplayType: 1,
                                isEmpty: false,
                                nodeLayoutType:1,
                                name: cell.name,
                                lineLayout:1})
                        }
                        
                    });                    
                } else {
                    resolve(null)
                }
            });
        });
    }

    loadChechMindContent(isElectron, rootId, sheetId) {
        let that = this;
        return new Promise(async resolve => {
            let id = sheetId == null || sheetId.length == 0 ? rootId : sheetId
            if (id == null || id.length == 0) {               
                return
            }
            if (isElectron) {
                let fileName = id + ".json"
                let path = that.getAppPath() + MindMapZipConstant.MindZIPLocalFilesFolderName + 
                        MindMapZipConstant.FileSeparator + MindMapZipConstant.MindZIPContentTemporaryFolderName +
                        MindMapZipConstant.FileSeparator + fileName
                if (!that.existsLocalFile(path)) {
                    resolve(null)
                    return
                }       
                that.loadJson(path).then((data) => {
                    let json = data;
                    let saveData = JSON.parse(json)
                    resolve({content:saveData.content, 
                        mindType:saveData.mindType, 
                        index:0,
                        styleIndex: 1,
                        mindDisplayType: 1,
                        isEmpty: false,
                        nodeLayoutType:1,
                        name: saveData.name,
                        time: saveData.lastchangetime,
                        lineLayout:1})
                })
            } else {
                let mindKey = ""
                let currentKey = Util.getCurrentTime() + "|" + id
                for(let key in localStorage) {
                    if (key.length == currentKey.length && key.indexOf("|") > 0) {
                        let keyArr = key.split("|")
                        if (keyArr.length != 2) {
                            continue
                        }
                        if (keyArr[1] == id) {
                            mindKey = key
                            break
                        }
                    }
                }
                if (mindKey == null || mindKey.length == 0) {
                    resolve(null)
                    return
                }
                let json = localStorage.getItem(mindKey);
                if (json == null || json.length == 0) {
                    resolve(null)
                    return
                }
                let saveData = JSON.parse(json)
                resolve({content:saveData.content, 
                    mindType:saveData.mindType, 
                    index:0,
                    styleIndex: 1,
                    mindDisplayType: 1,
                    isEmpty: false,
                    nodeLayoutType:1,
                    name: saveData.name,
                    time: saveData.lastchangetime,
                    lineLayout:1})
            }
        });
    }

    getMindmapSheets() {
        let that = this;
        return new Promise(async resolve => {
            let localpath = this.getLocalPath()
            if (localpath == null || localpath.length == 0) {
                resolve(null, {desc: getString(strings.Load_File_Error)})
                return
            }
            let contentJSPath = localpath + MindMapZipConstant.FileSeparator + MindMapZipConstant.MindZIPContentPath
            that.loadJson(contentJSPath).then((data) => {
                let json = data;
                let saveData = JSON.parse(json)
                if (saveData.data != null && saveData.data.length > 0) {
                    let count = saveData.data.length;
                    let mindSheets = new MindSheets()
                    mindSheets.totalNum =  count
                    mindSheets.pageSize = Math.max(count, 50);
                    let rootId = saveData.data[0].id
                    for (let index = 0; index < count; index++) {
                        const cell = saveData.data[index];
                        let sheetData = new SheetData()
                        sheetData.name = cell.name
                        sheetData.id = cell.id
                        sheetData.rootId = rootId
                        sheetData.cover = "";
                        sheetData.index = index;
                        sheetData.fileName = cell.fileName;
                        sheetData.type = cell.mindType;
                        if (cell.lastchangetime == null || cell.lastchangetime == 0) {
                            sheetData.lastchangetime = Util.getCurrentTime()
                        } else {
                            sheetData.lastchangetime = cell.lastchangetime;
                        }
                        
                        mindSheets.sheets.push(sheetData)
                    }
                    resolve(mindSheets)
                } else {
                    resolve(null, {desc: getString(strings.Load_File_Error)})
                }
            });
        });
    }

    loadJson(jsonpath) {
        let that = this
        return new Promise(async resolve => {
            try {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', HttpServerConfig.getLocalPathPrefix(jsonpath, that.appPath), true);
                xhr.responseType = 'blob';
                xhr.onload = () => {
                    if (xhr.status === 200) {
                        var fr = new FileReader();
                        fr.readAsText(xhr.response, "utf-8");
                        fr.addEventListener("loadend",(e) => {
                            var json = e.target.result;
                            resolve(json)
                        },false);
                    } else {
                        console.log("xhr.response xhr.status - ", xhr.status);
                        resolve("")
                    }
                };
                xhr.onerror = (error) => {
                    console.log("xhr.response error - ", error);
                    resolve("")
                };
                xhr.send();
            } catch (error) {
                console.log("loadJson error: ", error);
                resolve("")
            }
            
        });
    }

    loadLocalImg(url) {

    }

    existsLocalFile(url) {
        let localPath = this.getLocalPath()
        // if (localPath == null || localPath.length == 0) {
        //     return false;
        // }
        var fs = window.require('fs');
        if (url.startsWith("http") && localPath != null && localPath.length > 0) {
            let name = md5(HttpServerConfig.removeUrlPrefix(url))
            let type = Util.getUrlType(url)
            var path = this.getLocalPath() + MindMapZipConstant.FileSeparator + MindMapZipConstant.MindZIPFilesFolderName + MindMapZipConstant.FileSeparator + name
            if (type != null && type.length > 0) {
                path = path + "." + type;
            }
            let e = fs.existsSync(path)
            return e
        } else {
            let e = fs.existsSync(url)
            return e
        }
        return false;
    }
    
    getLocalFilePath(url, isAddPrefix = true) {
        if (url == null && url.length == 0) {
            return ""
        }
        let localPath = this.getLocalPath()
        if (url.startsWith("http") && localPath != null && localPath.length > 0) {
            let name = md5(HttpServerConfig.removeUrlPrefix(url))
            let type = Util.getUrlType(url)
            var path = localPath + MindMapZipConstant.FileSeparator + MindMapZipConstant.MindZIPFilesFolderName + MindMapZipConstant.FileSeparator + name
            if (type != null && type.length > 0) {
                path = path + "." + type;
            }
            var fs = window.require('fs');
            let e = fs.existsSync(path)
            if (e) {
                if (isAddPrefix) {
                    return HttpServerConfig.getLocalPathPrefix(path, this.appPath)
                } else {
                    return path
                }
            }
        }
        return url;
    } 

    setRootId(id, syncToClouded = false) {
        this.mindMapLocalData.setRootId(id, syncToClouded)
    }

    getRootId() {
        return this.mindMapLocalData.rootId
    }

    setSheetId(id) {
        this.mindMapLocalData.sheetId = id
    }

    getSheetId() {
        return this.mindMapLocalData.sheetId
    }

    setPath(path, local) {
        if (this.mindMapLocalData.path == path) {
            return;
        }
        this.mindMapLocalData.path = path
        this.mindMapLocalData.localPath = local
        if (path == null || path.length == 0 ||
            path.indexOf(MindMapZipConstant.MindZIPNewPathe) > -1) {
            this.mindMapLocalData.name = Util.getNameByUrl(local, false)
        } else {
            this.mindMapLocalData.name = Util.getNameByUrl(path)
        }
    }

    setParameter(value) {
        if (value == null || value.length == 0) {
            return;
        }
        this.parameter = new NewLocalMindParameter().format(value)
    }

    getPath() {
        return this.mindMapLocalData.path;
    }

    getName() {
        return this.mindMapLocalData.name;
    }

    getLocalPath() {
        return this.mindMapLocalData.localPath;
    }

    getAppPath() {
        return this.appPath;
    }

    isLoaclMind() {
        return this.mindMapLocalData.path != null && this.mindMapLocalData.path.length > 0
    }

    isLoaclNotSaved() {
        return this.mindMapLocalData.path != null && this.mindMapLocalData.path.indexOf(MindMapZipConstant.MindZIPNewPathe) > -1;
    }

    isCloudStorage(id) {
        return this.mindMapLocalData.isCloudStorage(id); 
    }

    getFileName(url, isPrefix = false) {
        let name = md5(HttpServerConfig.removeUrlPrefix(url))
        if (isPrefix) {
            let type = Util.getUrlType(url)
            if (type != null && type.length > 0) {
                name = name + "." + type;
            }
        }
        return name;
    }

    getUrlLocalPath(url) {
        if (!this.isLoaclMind()) {
            return "";
        }
        let name = this.getFileName(url, true)
        let type = Util.getUrlType(url)
        var path = this.getLocalPath() + MindMapZipConstant.FileSeparator + MindMapZipConstant.MindZIPFilesFolderName + MindMapZipConstant.FileSeparator + name
        return path;
    }

    getLocalFileRootPath() {
        if (!this.isLoaclMind()) {
            return "";
        }
        var path = this.getLocalPath() + MindMapZipConstant.FileSeparator + MindMapZipConstant.MindZIPFilesFolderName
        return path;
    }

    loadResourceData(url) {
        var that = this
        return new Promise(async resolve => {
            let srcHttp = HttpServerConfig.getMediaSrc(url)
            let blob = null;
            if (that.existsLocalFile(srcHttp)) {
                let path = that.getLocalFilePath(srcHttp);
                blob = await that.asyncLoadLocalFile(path)
            } else {
                let response = await fetch(srcHttp); 
                blob = await response.blob();                    
            }
            if (blob == null) {
                resolve(null)
            } else {
                resolve(blob)
            }
        });
    }

    loadResourceBuffer(url) {
        var that = this
        return new Promise(async resolve => {
            let srcHttp = HttpServerConfig.getMediaSrc(url)
            let buffer = null;
            if (that.existsLocalFile(srcHttp)) {
                let path = that.getLocalFilePath(srcHttp);
                buffer = await that.asyncLoadLocalFileByBuffer(path)
            } else {
                let response = await fetch(srcHttp); 
                buffer = await response.arrayBuffer();                    
            }
            if (buffer == null) {
                resolve(null)
            } else {
                resolve(buffer)
            }
        });
    }


    asyncLoadLocalFile(path) {
        return new Promise(resolve => {
            const request = new XMLHttpRequest();            
            request.responseType = "blob";
            request.open("GET", path);
            request.onload = function (oEvent) {
                if (request.status == 200) {
                    resolve(request.response)
                } else {
                    resolve(null)
                }
            }
            request.send()
        })
    }

    asyncLoadLocalFileByBuffer(path) {
        return new Promise(resolve => {
            const request = new XMLHttpRequest();            
            request.responseType = "arraybuffer";
            request.open("GET", path);
            request.onload = function (oEvent) {
                if (request.status == 200) {
                    resolve(request.response)
                } else {
                    resolve(null)
                }
            }
            request.send()
        })
    }
}

export default new LoadLocalXJMind()


