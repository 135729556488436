import MindmapDataRouter from "../../../../facade/MindmapDataRouter";
import MindMe from "../../../../facade/MindMe";

class MindMapLocalData {
    constructor() {
        this.mid = ""; //设备id
        this.id = ""; //用户id
        this.rootId = "";
        this.sheetId = "";
        this.name = "";
        this.path = "";
        this.localPath = "";
        this.isSyncToCloud = false;
    }

    isCloudStorage(id) {
        if (arguments.length == 0 || id == null || id == this.rootId) {
            return (this.rootId != null && this.rootId.length > 10) && this.isSyncToCloud;
        } else {
            return (id != null && id.length > 10);
        }
    }

    setRootId(id, syncToClouded = false) {
        this.rootId = id
        if (this.rootId == null || this.rootId.length < 10) {
            return
        }
        
        if (syncToClouded) {
            this.isSyncToCloud = true
        } else {
            MindmapDataRouter.postGetMindmap(
                { id: this.rootId},
                (res) => {
                    this.isSyncToCloud = true
                },
                (error) => {
                    this.isSyncToCloud = false
                }
            );
        }
    }
}
export default MindMapLocalData