import { postMindmapGetMindmap, postMindmapListSheets, postMindmapUploadMindmap } from "../../common/netWork/mind_map_api"
import LoadLocalXJMind from "../core/tools/mapfile/LoadLocalXJMind";

class MindmapDataRouter {
    constructor() {
        this.isElectron = true;
    }

    postGetMindmap(req, onOk, onFail, eventually, headers, onProgress) {
        if (!LoadLocalXJMind.isCloudStorage() && req.path != null && req.path == this.getPath() && this.getLocalPath() != null && this.getLocalPath().length > 0) {
            LoadLocalXJMind.loadMainContent(req.path, this.getLocalPath(), req.id, req.sheetId).then((data) => {
                if (data != null) {
                    let obj = {archiveId: "",
                    audio: "",
                    audioSeconds: 0,
                    content: data.content,
                    cover: "",
                    createTime: 0,
                    entityId: "",
                    id: "",
                    index: data.index,
                    isArchived: false,
                    isEmpty: false,
                    lineLayout: data.lineLayout,
                    machineId: "",
                    mindDisplayType: data.mindDisplayType,
                    modifyTime: "",
                    name: data.name,
                    nodeLayoutType: data.nodeLayoutType,
                    parentId: "",
                    sheetId: "",
                    sheetNum: "",
                    styleIndex: data.styleIndex,
                    type: data.mindType,
                    userId: "",}
                    onOk(obj)
                } else {
                    onFail({desc:"加载数据错误"})
                }
            });
        } else {
            postMindmapGetMindmap(req, onOk, onFail, eventually, headers, onProgress)
        }
    }

    postUploadMindmap(req, onOk, onFail, eventually, headers, onProgress) {
        postMindmapUploadMindmap(req, onOk, onFail, eventually, headers, onProgress)
    }

    postMindmapSheets(req,onOk,onFail,eventually,headers,onProgress) {
        if (!LoadLocalXJMind.isCloudStorage() && 
            this.getLocalPath() != null && this.getLocalPath().length > 0 && 
            (req.rootId == null || req.rootId == LoadLocalXJMind.getRootId())) {
            LoadLocalXJMind.getMindmapSheets().then((data, error) => {
                if (data != null) {
                    onOk(data)
                } else if (error != null) {
                    onFail(error)
                }
            })
        } else {
            postMindmapListSheets(req,onOk,onFail,eventually,headers,onProgress)
        }
    }

    setPath(path, local) {
        LoadLocalXJMind.setPath(path, local)
    }

    setParameter(value) {
        LoadLocalXJMind.setParameter(value)
    }

    getPath() {
        return LoadLocalXJMind.getPath();
    }

    getLocalPath() {
        return LoadLocalXJMind.getLocalPath();
    }
}

export default new MindmapDataRouter()